<template>
  <v-container
    fluid
    class="pa-0"
  >
    <div class="hidden-md-and-down">
      <hero-component
        align="center"
        background-url="/img/About-Hero-Section.jpg"
      >
        {{ $t("ourTeamPage.heroImage.text1") }}<br>{{ $t("ourTeamPage.heroImage.text2") }}
      </hero-component>
      <div class="section2-desk">
        <v-row>
          <v-col class="s2-title">
            {{ $t("ourTeamPage.pageTitle") }}
          </v-col>
        </v-row>
        <v-row class="pt-13">
          <v-col class="s2-sub">
            {{ $t("ourTeamPage.pageSubTitle.text") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="d-flex align-center"
          >
            <v-icon
              large
              left
              class="primary--text pb-2"
            >
              mdi-check-bold
            </v-icon>
            <span class="s2-text">{{ $t("ourTeamPage.pageSubTitle.bulletPoint1") }}</span>
          </v-col>
          <v-col
            cols="12"
            class="d-flex align-start"
          >
            <v-icon
              large
              left
              class="primary--text pb-2"
            >
              mdi-check-bold
            </v-icon>
            <span class="s2-text">{{ $t("ourTeamPage.pageSubTitle.bulletPoint2") }}</span>
          </v-col>
          <v-col
            cols="12"
            class="d-flex align-start"
          >
            <v-icon
              large
              left
              class="primary--text pb-2"
            >
              mdi-check-bold
            </v-icon>
            <span class="s2-text">{{ $t("ourTeamPage.pageSubTitle.bulletPoint3") }}</span>
          </v-col>
        </v-row>
        <v-row class="pb-16">
          <v-col class="s2-big-text">
            {{ $t("ourTeamPage.pageSubTitle.bigText1") }} <br> {{ $t("ourTeamPage.pageSubTitle.bigText2") }} <br> {{ $t("ourTeamPage.pageSubTitle.bigText3") }}
          </v-col>
        </v-row>
      </div>
      <div class="section3-desk">
        <v-row class="d-flex justify-center">
          <v-col class="s3-title d-flex justify-center">
            {{ $t("ourTeamPage.ourCoreValue.title") }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center pt-16">
          <v-col class="text-center">
            <v-icon
              large
              class="mint--text"
            >
              mdi-lock-check
            </v-icon>
          </v-col>
          <v-col class="text-center">
            <v-icon
              large
              class="mint--text"
            >
              mdi-check-circle
            </v-icon>
          </v-col>
          <v-col class="text-center">
            <v-icon
              large
              class="mint--text"
            >
              mdi-sort-bool-descending-variant
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col class="s3-sub text-center">
            {{ $t("ourTeamPage.ourCoreValue.subText.title1") }}
          </v-col>
          <v-col class="s3-sub text-center">
            {{ $t("ourTeamPage.ourCoreValue.subText.title2") }}
          </v-col>
          <v-col class=" s3-sub text-center">
            {{ $t("ourTeamPage.ourCoreValue.subText.title3") }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col class="s3-text text-center">
            {{ $t("ourTeamPage.ourCoreValue.subText.content1") }}
          </v-col>
          <v-col class="s3-text text-center">
            {{ $t("ourTeamPage.ourCoreValue.subText.content2") }}
          </v-col>
          <v-col class=" s3-text text-center">
            {{ $t("ourTeamPage.ourCoreValue.subText.content3") }}
          </v-col>
        </v-row>
      </div>
      <div class="section4-desk">
        <v-row>
          <v-col class="s4-title text-center">
            {{ $t("ourTeamPage.team.title") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="s4-sub">
            {{ $t("ourTeamPage.team.text") }}
          </v-col>
        </v-row>
        <!------------------------Our Team Avatar Start------------------------------>
         <v-row
         class="d-flex justify-space-between my-0"
         v-for="(rowItems, index) in rows"
         :key="index"
         >
          <v-col
          class="d-flex align-stretch pb-0"
          v-for="(item, itemIndex) in rowItems"
          :key="itemIndex"
          :cols="4"
          >
            <v-card
              class="secondary pa-2 d-flex justify-center mb-0"
            >
              <v-card-text>
                <v-img
                  :src="item.img"
                  height="155"
                  width="155"
                  class="mx-auto mb-4 rounded-circle"
                ></v-img>
                <v-row>
                  <v-col class="exec-name d-flex justify-center">
                    {{ item.name }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="exec-title">
                    {{ item.title }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="exec-card-txt">
                     {{ item.description }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
         </v-row>
        <!------------------------Our Team Avatar End------------------------------>
        <v-row>
          <v-col class="s4-title text-center style-advisor">
            {{ $t("ourTeamPage.team.titleAdvisor") }}
          </v-col>
        </v-row>
        <v-row
         class="d-flex justify-space-between my-0"
         >
          <v-col
          class="d-flex align-stretch pb-0"
          v-for="(item, itemIndex) in getAdvisors"
          :key="itemIndex"
          cols="4"
          >
            <v-card
              class="secondary pa-2 d-flex justify-center mb-0"
            >
              <v-card-text>
                <v-img
                  :src="item.img"
                  height="155"
                  width="155"
                  class="mx-auto mb-4 rounded-circle"
                ></v-img>
                <v-row>
                  <v-col class="exec-name d-flex justify-center">
                    {{ item.name }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="exec-card-txt">
                     {{ item.description }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
       </v-row>
      </div>
      <div class="sec5-dsk">
        <div class="sec5-cnt">
          <v-row>
            <v-col class="sec5-title">
              {{ $t("ourTeamPage.sectionBottom.title") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="sec5-sub">
              {{ $t("ourTeamPage.sectionBottom.subTitle") }}
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col>
              <v-btn
                large
                class="mint apply-btn-txt"
                to="/"
              >
                {{ $t("ourTeamPage.sectionBottom.subTitleText") }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-img
          src="/img/dashboard/mobile-app-mockup.png"
          :class="xlOnly ? 'sec5-xl-phone' : 'sec5-dsk-phone'"
        ></v-img>
      </div>
      <div class="white-bar-tablet"></div>
    </div>
    <!------------------------TABLET------------------------------>
    <div
      v-if="smOnly || mdOnly"
    >
      <div
        class="section1-tablet"
      >
        <div class="s1-title-tablet">
          {{ $t("ourTeamPage.heroImage.text1") }}<br>{{ $t("ourTeamPage.heroImage.text2") }}
        </div>
      </div>
      <div class="section2-tablet px-4">
        <v-row>
          <v-col class="s2-title-tablet">
            {{ $t("ourTeamPage.pageTitle") }}
          </v-col>
        </v-row>
        <v-row class="pt-13">
          <v-col class="s2-sub">
            {{ $t("ourTeamPage.pageSubTitle.text") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="d-flex align-center"
          >
            <v-icon
              large
              left
              class="primary--text pb-2"
            >
              mdi-check-bold
            </v-icon>
            <span class="s2-text">{{ $t("ourTeamPage.pageSubTitle.bulletPoint1") }}</span>
          </v-col>
          <v-col
            cols="12"
            class="d-flex align-start"
          >
            <v-icon
              large
              left
              class="primary--text pb-2"
            >
              mdi-check-bold
            </v-icon>
            <span class="s2-text">{{ $t("ourTeamPage.pageSubTitle.bulletPoint2") }}</span>
          </v-col>
          <v-col
            cols="12"
            class="d-flex align-start"
          >
            <v-icon
              large
              left
              class="primary--text pb-2"
            >
              mdi-check-bold
            </v-icon>
            <span class="s2-text">{{ $t("ourTeamPage.pageSubTitle.bulletPoint3") }}</span>
          </v-col>
          <p class="s2-text ml-14 pt-6">
            {{ $t("ourTeamPage.pageSubTitle.otherText") }}
          </p>
        </v-row>
        <v-row class="pb-16">
          <v-col class="s2-big-text">
            {{ $t("ourTeamPage.pageSubTitle.bigText1") }}<br> {{ $t("ourTeamPage.pageSubTitle.bigText2") }} <br> {{ $t("ourTeamPage.pageSubTitle.bigText3") }}
          </v-col>
        </v-row>
      </div>
      <div class="section3-tablet pt-16 px-4">
        <v-row class="d-flex justify-center">
          <v-col class="s3-title d-flex justify-center">
            {{ $t("ourTeamPage.ourCoreValue.title") }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center pt-16">
          <v-col class="text-center">
            <v-icon
              large
              class="mint--text"
            >
              mdi-lock-check
            </v-icon>
          </v-col>
          <v-col class="text-center">
            <v-icon
              large
              class="mint--text"
            >
              mdi-check-circle
            </v-icon>
          </v-col>
          <v-col class="text-center">
            <v-icon
              large
              class="mint--text"
            >
              mdi-sort-bool-descending-variant
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col class="s3-sub text-center">
            {{ $t("ourTeamPage.ourCoreValue.subText.title1") }}
          </v-col>
          <v-col class="s3-sub text-center">
            {{ $t("ourTeamPage.ourCoreValue.subText.title2") }}
          </v-col>
          <v-col class=" s3-sub text-center">
            {{ $t("ourTeamPage.ourCoreValue.subText.title3") }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col class="s3-text text-center">
            {{ $t("ourTeamPage.ourCoreValue.subText.content1") }}
          </v-col>
          <v-col class="s3-text text-center">
            {{ $t("ourTeamPage.ourCoreValue.subText.content2") }}
          </v-col>
          <v-col class=" s3-text text-center">
            {{ $t("ourTeamPage.ourCoreValue.subText.content3") }}
          </v-col>
        </v-row>
      </div>
      <div class="section4-tablet px-4 pb-16">
        <v-row>
          <v-col class="s4-title text-center">
            {{ $t("ourTeamPage.team.title") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="s4-sub">
            {{ $t("ourTeamPage.team.text") }}
          </v-col>
        </v-row>
        <v-row
        class="d-flex justify-center my-0"
         v-for="(rowItems, index) in rows"
         :key="index"
        >
          <v-col
            cols="6"
            class="d-flex align-stretch pb-0"
            v-for="(item, itemIndex) in rowItems"
            :key="itemIndex"
          >
            <v-card
              class="secondary pa-2 d-flex justify-center mb-0"
            >
              <v-card-text>
                <v-img
                  :src="item.img"
                  height="155"
                  width="155"
                  class="mx-auto mb-4 rounded-circle"
                ></v-img>
                <v-row>
                  <v-col class="exec-name d-flex justify-center">
                    {{ item.name }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="exec-title">
                     {{ item.title }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="exec-card-txt">
                    {{ item.description }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
          <v-row>
          <v-col class="s4-title text-center style-advisor">
            {{ $t("ourTeamPage.team.titleAdvisor") }}
          </v-col>
        </v-row>
        <v-row
         class="d-flex justify-space-between my-0"
         >
          <v-col
          class="d-flex align-stretch pb-0 mx-auto"
          v-for="(item, itemIndex) in getAdvisors"
          :key="itemIndex"
          cols="6"
          >
            <v-card
              class="secondary pa-2 d-flex justify-center mb-0"
            >
              <v-card-text>
                <v-img
                  :src="item.img"
                  height="155"
                  width="155"
                  class="mx-auto mb-4 rounded-circle"
                ></v-img>
                <v-row>
                  <v-col class="exec-name d-flex justify-center">
                    {{ item.name }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="exec-card-txt">
                     {{ item.description }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
       </v-row>
      </div>
      <div class="sec5-tablet">
        <v-row class="pt-16">
          <v-col class="sec5-title d-flex justify-center">
            {{ $t("ourTeamPage.sectionBottom.title") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="sec5-sub d-flex justify-center">
            {{ $t("ourTeamPage.sectionBottom.subTitle") }}
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="d-flex justify-center">
            <v-btn
              large
              class="mint apply-btn-txt"
              to="/"
            >
              {{ $t("ourTeamPage.sectionBottom.subTitleText") }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="white-bar-tablet"></div>
    </div>
    <!-------------------------MOBILE------------------------------>
    <div v-if="xsOnly">
      <div
        class="section1-mobile"
      >
      </div>
      <div class="primary px-4 pt-4 pb-8">
        <v-row>
          <v-col
            class="s1-title-mobile text-center"
          >
            {{ $t("ourTeamPage.heroImage.text1") }} {{ $t("ourTeamPage.heroImage.text2") }}
          </v-col>
        </v-row>
      </div>
      <div class="section2-mobile px-4">
        <v-row>
          <v-col class="s2-title-tablet pt-12">
            {{ $t("ourTeamPage.pageTitle") }}
          </v-col>
        </v-row>
        <v-row class="pt-10">
          <v-col class="s2-sub">
            {{ $t("ourTeamPage.pageSubTitle.text") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="d-flex align-start"
          >
            <v-icon
              left
              class="primary--text pb-2"
            >
              mdi-check-bold
            </v-icon>
            <span class="s2-text">{{ $t("ourTeamPage.pageSubTitle.bulletPoint1") }}.</span>
          </v-col>
          <v-col
            cols="12"
            class="d-flex align-start"
          >
            <v-icon
              left
              class="primary--text pb-2"
            >
              mdi-check-bold
            </v-icon>
            <span class="s2-text">{{ $t("ourTeamPage.pageSubTitle.bulletPoint2") }}</span>
          </v-col>
          <v-col
            cols="12"
            class="d-flex align-start"
          >
            <v-icon
              left
              class="primary--text pb-2"
            >
              mdi-check-bold
            </v-icon>
            <span class="s2-text">{{ $t("ourTeamPage.pageSubTitle.bulletPoint3") }}</span>
          </v-col>
          <p class="s2-text ml-14 pt-6">
            {{ $t("ourTeamPage.pageSubTitle.otherText") }}
          </p>
        </v-row>
        <v-row class="pb-16">
          <v-col class="s2-big-text-mobile text-center">
            {{ $t("ourTeamPage.pageSubTitle.bigText1") }} {{ $t("ourTeamPage.pageSubTitle.bigText2") }} {{ $t("ourTeamPage.pageSubTitle.bigText3") }}
          </v-col>
        </v-row>
      </div>
      <div class="section3-mobile pt-16 px-4">
        <v-row class="d-flex justify-center">
          <v-col class="s3-title text-center">
            {{ $t("ourTeamPage.ourCoreValue.title") }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center pt-16">
          <v-col
            cols="12"
            class="text-center"
          >
            <v-icon
              large
              class="mint--text"
            >
              mdi-lock-check
            </v-icon>
          </v-col>
          <v-col
            cols="12"
            class="s3-sub text-center"
          >
            {{ $t("ourTeamPage.ourCoreValue.subText.title1") }}
          </v-col>
          <v-col
            cols="12"
            class="s3-text text-center pb-8"
          >
            {{ $t("ourTeamPage.ourCoreValue.subText.content1") }}
          </v-col>
          <v-col
            cols="12"
            class="text-center"
          >
            <v-icon
              large
              class="mint--text"
            >
              mdi-check-circle
            </v-icon>
          </v-col>
          <v-col
            cols="12"
            class="s3-sub text-center"
          >
            {{ $t("ourTeamPage.ourCoreValue.subText.title2") }}
          </v-col>
          <v-col
            cols="12"
            class="s3-text text-center pb-8"
          >
            {{ $t("ourTeamPage.ourCoreValue.subText.content2") }}
          </v-col>
          <v-col class="text-center">
            <v-icon
              large
              class="mint--text"
            >
              mdi-sort-bool-descending-variant
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col class=" s3-sub text-center">
            {{ $t("ourTeamPage.ourCoreValue.subText.title3") }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col class=" s3-text text-center pb-16">
            {{ $t("ourTeamPage.ourCoreValue.subText.content3") }}
          </v-col>
        </v-row>
      </div>
      <div class="section4-tablet px-2 pb-16">
        <v-row>
          <v-col class="s4-title-mobile text-center">
            {{ $t("ourTeamPage.team.title") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="s4-sub-mobile pb-10">
            {{ $t("ourTeamPage.team.text") }}
          </v-col>
        </v-row>
        <v-row
        class="d-flex justify-center my-0"
        v-for="(rowItems, index) in rows"
        :key="index"
        >
          <v-col
            cols="12"
            class="px-4"
             v-for="(item, itemIndex) in rowItems"
            :key="itemIndex"
          >
            <v-card
              class="secondary pa-2 d-flex justify-center mb-0"
            >
              <v-card-text>
                <v-img
                  :src="item.img"
                  height="155"
                  width="155"
                  class="mx-auto mb-4 rounded-circle"
                ></v-img>
                <v-row>
                  <v-col class="exec-name d-flex justify-center">
                    {{ item.name }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="exec-title">
                    {{ item.title }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="exec-card-txt">
                  {{ item.description}}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

          <v-row>
          <v-col class="s4-title-mobile text-center style-advisor">
            {{ $t("ourTeamPage.team.titleAdvisor") }}
          </v-col>
        </v-row>
        <v-row
         class="d-flex justify-space-between my-0"
         >
          <v-col
          class="d-flex align-stretch pb-0"
          v-for="(item, itemIndex) in getAdvisors"
          :key="itemIndex"
          cols="12"
          >
            <v-card
              class="secondary pa-2 d-flex justify-center mb-0"
            >
              <v-card-text>
                <v-img
                  :src="item.img"
                  height="155"
                  width="155"
                  class="mx-auto mb-4 rounded-circle"
                ></v-img>
                <v-row>
                  <v-col class="exec-name d-flex justify-center">
                    {{ item.name }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="exec-card-txt">
                     {{ item.description }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
       </v-row>
      </div>
      <div class="sec5-mobile pb-16 px-2">
        <v-row class="pt-16">
          <v-col class="sec5-title text-center">
            {{ $t("ourTeamPage.sectionBottom.title") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="sec5-sub text-center">
            {{ $t("ourTeamPage.sectionBottom.subTitle") }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col class="d-flex justify-center">
            <v-btn
              large
              class="mint text-h5 darkgrey--text font-weight-bold mr-0"
              to="/"
            >
              {{ $t("ourTeamPage.sectionBottom.subTitleText") }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="white-bar-tablet"></div>
    </div>
  </v-container>
</template>

<script>
  import HeroComponent from '@/components/base/Hero'

  export default {
    components: {
      HeroComponent
    },
    computed: {
      rows () {
        const ele = this.smOnly ? 2 : this.xsOnly ? 1 : 3
        const rows = []
        for (let i = 0; i < this.getTeam.length; i += ele) {
          rows.push(this.getTeam.slice(i, i + ele))
        }
        return rows
      },
      getTeam () {
        return this.$t('ourTeamPage.team.peoples')
      },
      getAdvisors () {
        return this.$t('ourTeamPage.team.advisor')
      },
      xlOnly () {
        return this.$vuetify.breakpoint.xlOnly
      },
      xsOnly () {
        return this.$vuetify.breakpoint.xsOnly
      },
      smOnly () {
        return this.$vuetify.breakpoint.smOnly
      },
      mdOnly () {
        return this.$vuetify.breakpoint.mdOnly
      },
      lgOnly () {
        return this.$vuetify.breakpoint.lgOnly
      },
    }

  }
</script>

<style lang="scss" scoped>
.section2-desk {
  background: #FFFFFF;
  padding-top: 93px;
  padding-left: 147px;
  padding-right: 147px;
}

.s2-title {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 38px;
line-height: 110%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
}

.s2-sub {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 28px;
line-height: 120%;
letter-spacing: -0.424019px;
color: #292F36;
}

.s2-text {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 150%;
color: #292F36;
}

.s2-big-text {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 38px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #005F6C;
}

.section3-desk{
  height: 590px;
  background-image: url('/img/secondary-knit.jpg');
  background-position: center;
  background-size: cover;
  padding-top: 75px;
  padding-left: 165px;
  padding-right: 165px;
}

.s3-title {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 52px;
line-height: 110%;
letter-spacing: -0.511589px;
color: #FFFFFF;
}

.s3-sub {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 21px;
line-height: 24px;
/* identical to box height, or 114% */

text-align: center;

/* white */

color: #FFFFFF;
}

.s3-text {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
text-align: center;
color: #FFFFFF;
}

.section4-desk {
  padding-top: 79px;
  padding-left: 131px;
  padding-right: 131px;
  padding-bottom: 146px;
  background: #FFFFFF;
}

.s4-title {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 52px;
line-height: 110%;
letter-spacing: -0.511589px;
color: #292F36;
}

.s4-sub {
font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 120%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
}

.exec-name {
  font-family: 'Sora';
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 110%;
/* or 33px */

display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.424019px;
text-align: center;
color: #AAF0C1;
}

.exec-title {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 150%;
/* or 24px */

text-align: center;

/* white */

color: #FFFFFF;
}

.exec-card-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
text-align: center;
letter-spacing: -0.42px;
color: #FFFFFF;
}
.sec5-dsk {
  position: relative;
  height: 356px;
  background: url('/img/dash-pattern.jpg');
}
.sec5-dsk-phone {
  position: absolute;
left: 726px;
top: -78px;
}
.sec5-xl-phone {
  position: absolute;
left: 1100px;
top: -78px;
}
.sec5-cnt {
  position: absolute;
width: 516px;
height: 157px;
left: 794px;
top: 75px;
left: 214px;
}
.sec5-title {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
/* or 46px */
letter-spacing: -0.424019px;
/* Color Palette/White (100%) */
color: #FFFFFF;
}
.sec5-sub {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
/* identical to box height, or 24px */
/* Color Palette/White (100%) */
color: #FFFFFF;
}

.apply-btn-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 19px;
line-height: 125%;
letter-spacing: 0.00892857em;
color: #292F36 !important;
}

.section6-desk {
  background: #FFFFFF;
  padding-top: 93px;
  padding-left: 147px;
  padding-right: 147px;
  padding-bottom: 87px;
}

/*---------------------TABLET---------------------*/
.section1-tablet {
  position: relative;
  height: 400px;
  background-image: url('/img/About-Hero-Section.jpg');
  background-position: center;
  background-size: cover;
}

.s1-title-tablet {
  position:absolute;
  top: 250px;
  left: 50px;
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 52px;
line-height: 110%;
letter-spacing: -0.511589px;
color: #FFFFFF;
}

.section2-tablet {
  background: #FFFFFF;
  padding-top: 93px;
}

.s2-title-tablet {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 38px;
line-height: 110%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
}

.section3-tablet{
  height: 590px;
  background-image: url('/img/secondary-knit.jpg');
  background-position: center;
  background-size: cover;

}

.section4-tablet{
  padding-top: 79px;
  background: #FFFFFF;
}
.sec5-tablet {
  height: 300px;
  background-image: url('/img/dash-pattern.jpg');
  background-position: center;
  background-size: cover;
}

.section6-tablet {
  background: #FFFFFF;
  padding-top: 93px;
  padding-bottom: 87px;
}

/*---------------------MOBILE---------------------*/
.section1-mobile {
  position: relative;
  height: 385px;
  background-image: url('/img/About-Hero-Section-Mobile.jpg');
  background-position: center;
  background-size: cover;
}

.s1-title-mobile {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
letter-spacing: -0.511589px;
color: #FFFFFF;
}

.section2-mobile {
  background-image: url('/img/white-pattern-mobile-pet.jpg');
  background-position: center;
  background-size: cover;
}

.s2-title-tablet {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
}

.section3-mobile{
  background-image: url('/img/secondary-knit.jpg');
  background-position: center;
  background-size: cover;
}
.section4-tablet{
  padding-top: 79px;
  background: #FFFFFF;
}
.sec5-mobile {
  background-image: url('/img/dash-pattern.jpg');
  background-position: center;
  background-size: cover;
}

.section6-mobile {
  background-image: url('/img/white-pattern-mobile-pet.jpg');
  padding-top: 93px;
  padding-bottom: 87px;
}

.snow-white {
  background-color: #FFFFFF;
}

.s2-big-text-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 26px;
line-height: 120%;
text-align: center;
letter-spacing: -0.511589px;
color: #005F6C;
  }

  .s4-title-mobile {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
letter-spacing: -0.511589px;
color: #292F36;
}

.s4-sub-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 26px;
line-height: 120%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
}

.white-bar-tablet {
  height: 110px;
  background: #FFFFFF;
}

.style-advisor {
  margin-top: 30px;
}
</style>
